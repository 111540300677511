<script>
import Layout from "../../layouts/main.vue";
import axios from "axios";
import Swal from "sweetalert2";
export default {
  components: {
    Layout,
  },
  data() {
    return {
      name: {
        val: "",
        isValid: true,
      },
      password: {
        val: "",
        isValid: true,
      },
      email: {
        val: "",
        isValid: true,
      },
      phone: {
        val: "",
      },
      ip: {
        val: "",
      },
      patients: [],
      id: "",
      formIsValid: true,
    };
  },
  methods: {
    clearValidity(input) {
      this[input].isValid = true;

    },
    validateForm() {
      if (this.name.val === "") {
        this.name.isValid = false;
        this.formIsValid = false;
      }
      if (this.password.val === "") {
        this.password.isValid = false;
        this.formIsValid = false;
      }
      if (this.email.val === "") {
        this.email.isValid = false;
        this.formIsValid = false;
      }
      else {
        this.formIsValid = true;
      }
    },
    async getPatients() {
      const response = await axios.get(
        "https://api.doctosoft.com/index.php/api/user"
      );
      this.patients = response.data;

      const lastEl = this.patients[this.patients.length - 1];

      this.id = lastEl.id;

      this.successmsg();
    },
    successmsg() {
      Swal.fire(
        "Good job!",
        "Created new Patient successfully " ,
        "success"
      );
    },
    errormsg() {
      Swal.fire("Invalid Form", "Please fix the errors and try again later", "error");
    },

    async addPatient() {
      this.validateForm();
      if (!this.formIsValid) {
        this.errormsg();
        return;
      } else {
        var formdata = new FormData();
        formdata.append("name", this.name.val);
        formdata.append("password", this.password.val);
        formdata.append("email", this.email.val);
        formdata.append("phone", this.phone.val);
        formdata.append("ip", this.ip.val);

        await axios.post(
          " https://api.doctosoft.com/index.php/api/user",
          formdata
        );
        this.getPatients();

        this.$router.push("/admin/patients");
      }
    },
  },
};
</script>

<template>
  <Layout>
    <div class="back-btn">
      <div class="arrow">
        <button class="btn btn-light" @click="$router.push('/admin/patients')"><i
            class="mdi mdi-chevron-left"></i></button>
      </div>
      <div class="action-btn">
        <b-button class="btn-soft-info waves-effect waves-light"
          @click="$router.push('/admin/patients')">Cancel</b-button>
        <!-- <button class="btn btn-light" @click="$router.push('/admin/patients')">Cancel</button> -->
        <button class="btn  btn-md btn btn-primary" type="submit" @click="addPatient">Save</button>
      </div>
    </div>
    <!-- <div class="cancel-btn">
      </div>
      <div class="add-btn">
  <<<<<<< HEAD
      </div> -->
    <b-row class="card-main">
      <b-col sm="6" xl="10">
        <b-card no-body>
          <form class="needs-validation" @submit.prevent="addPatient">

            <div class="mb-3" :class="{ invalid: !name.isValid }">
              <label for="name" class="form-label">Name <span class="text-danger">*</span></label>
              <input type="text" class="form-control" v-model.trim="name.val" placeholder="Enter name"
                @blur="clearValidity('name')" />
              <!-- <p v-if="!name.isValid">Name Must not be Empty</p> -->
            </div>

            <div class="mb-3" :class="{ invalid: !password.isValid }">
              <label for="password" class="form-label">Password <span class="text-danger">*</span></label>
              <input type="password" class="form-control" v-model.trim="password.val" placeholder="Enter Password"
                @blur="clearValidity('password')" />
              <!-- <p v-if="!password.isValid">Password Must not be Empty</p> -->
            </div>

            <div class="mb-3" :class="{ invalid: !email.isValid }">
              <label for="email" class="form-label">Email <span class="text-danger">*</span></label>
              <input type="email" class="form-control" v-model.trim="email.val" placeholder="Enter Email"
                @blur="clearValidity('email')" />
              <!-- <p v-if="!email.isValid">Email Must not be Empty</p> -->
            </div>
            <div class="mb-3">
              <label for="phone" class="form-label">Phone <span class="text-danger">(optional)</span></label>
              <input type="text" class="form-control" v-model.trim="phone.val" placeholder="Enter Phone" />
              <!-- <p v-if="!phone.isValid">Phone Must not be Empty</p> -->
            </div>
            <div class="mb-3">
              <label for="ip" class="form-label">Ip <span class="text-danger">(optional)</span></label>
              <input type="text" class="form-control" v-model.trim="ip.val" placeholder="Enter Ip" />
              <!-- <p v-if="!ip.isValid">Ip Must not be Empty</p> -->
            </div>
          </form>
        </b-card>
      </b-col>
    </b-row>
  </Layout>
</template>